#info-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#roey-peleg-arrest {
  max-height: 350px;
}

#rabin-section {
  background-size: unset !important;
  background-position: 50% 0 !important;
}

#balfur-section-content {
  display: grid;
  gap: 12.5px;
}

#seeyou-section {
  display: block;
  min-height: 325px;
  background-position: 40%;
}

#seeyou-section-text {
  text-align: center;
  background: #000000c4;
  border-radius: 10px;
  padding: 28px;
}

.compact-feed-section {
  min-height: unset !important;
  padding: 0 !important;
  grid-template-columns: 1fr !important;
  grid-template-rows: 120px 235px 70px !important;
}

.compact-feed-section-text {
  margin: 20px;
  text-align: center;
}

.compact-feed-section-text p {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

@media (min-width: 1024px) {
  #intro-section {
    grid-template-rows: 235px 430px;
  }

  #info-box {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  #gesher {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  #pinkfront {
    grid-column: 3 / -1;
    grid-row: 1;
  }

  #roey-peleg-arrest {
    grid-column: 3 / -1;
    grid-row: 2;
    max-height: unset;
  }

  #rabin-section {
    background-size: cover !important;
  }

  #rabin-square-picures {
    grid-template-columns: 1fr 0.5fr 0.5fr 1fr;
    grid-template-rows: 330px 300px;
  }

  #candles {
    grid-column: 1 / 3;
  }

  #candles2 {
    grid-column: 3 / -1;
  }

  #clock-square-rabin {
    grid-row: 2;
    grid-column: 1 / 2;
  }

  #zion-square-rabin {
    grid-column: 2 / 4;
  }

  #nationwide-section {
    grid-template-rows: 185px;
    grid-auto-rows: 240px;
    padding-top: 50px;
  }

  .nationwide-section-text {
    grid-column: 1 / -1;
    text-align: center;
  }

  .nationwide-section-text p {
    margin: 10px auto;
  }

  #nationwide-section p:nth-of-type(2) {
    display: none;
  }

  #seeyou-section-text {
    margin-top: -20px;
    padding: 30px 40px;
  }

  #balfur-section-text {
    margin-top: -60px;
  }

  #balfur-section-text,
  #seeyou-section-text {
    justify-self: center;
    align-self: center;
    text-align: center;
    grid-row: 1;
    grid-column: 1 / -1;
  }

  #balfur-section-content {
    grid-row: 2 / -1;
    grid-column: 1 / -1;
    grid-template-columns: 1fr 0.5fr 0.7fr;
    grid-template-rows: 235px 270px 320px;
    gap: 20px;
    padding: 50px;
    background: #f83a85bd;
  }

  .balfur-section-image:nth-child(1) {
    grid-row: 1;
    grid-column: 1 / 3;
  }

  .balfur-section-image:nth-child(2) {
    grid-row: 3;
    grid-column: 1 / -1;
  }

  .balfur-section-image:nth-child(3) {
    grid-row: 2;
    grid-column: 1 / 2;
  }

  .balfur-section-image:nth-child(4) {
    grid-row: 2;
    grid-column: 2 / 3;
  }

  .balfur-section-image:nth-child(5) {
    grid-row: 1 / 3;
    grid-column: 3 / -1;
  }

  #seeyou-section {
    min-height: 600px !important;
    background-position: center 0;
  }

  #seeyou-section-text p {
    max-width: 60%;
    margin: 10px auto;
  }
}

.weekly-footer {
  padding: 25px 10px;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.weekly-footer p {
  margin: 0;
}

.seeyou-section-social {
  margin-top: 25px;
}

.seeyou-section-social img {
  width: 40px;
  margin-right: 15px;
  filter: invert(54%) sepia(35%) saturate(5510%) hue-rotate(213deg) brightness(99%) contrast(104%);
  transition: filter 0.25s;
  user-select: none;
}

.seeyou-section-social a:hover img {
  filter: invert(1);
}

.alef-logo {
  width: 25px;
  margin: 0 5px;
}

/** Safari Grid Layout Issues **/

.grid-max-content-nosupport .weekly-section {
  grid-auto-rows: 270px;
}

.grid-max-content-nosupport #intro-section #info-box {
  padding: 45px 30px;
  font-size: 15px;
}

@media (min-width: 1024px) {
  .grid-max-content-nosupport #intro-section #info-box {
    font-size: 17px;
  }
}

.grid-max-content-nosupport #nationwide-section h2:nth-of-type(1) {
  margin-top: 10px;
}

.grid-max-content-nosupport #nationwide-section p:nth-of-type(1) {
  margin-top: 20px;
}
