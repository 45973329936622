@import '~antd/dist/antd.css';

@font-face {
  font-family: 'Simpler';
  font-weight: 200;
  font-display: fallback;
  src: local('Simpler Light'), url('./assets/fonts/Simpler-Light.woff') format('woff');
}

@font-face {
  font-family: 'Simpler';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Simpler Regular'), url('./assets/fonts/Simpler-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Simpler';
  font-weight: 600;
  font-display: fallback;
  src: local('Simpler Bold'), url('./assets/fonts/Simpler-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Simpler';
  font-weight: 800;
  src: local('Simpler Black'), url('./assets/fonts/Simpler-Black.woff') format('woff');
}

@font-face {
  font-family: 'Caravan';
  font-weight: 400;
  src: local('Caravan DL 2.0 AAA'), url('./assets/fonts/caravan-90-aaa.woff2') format('woff2');
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: Simpler, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6fa;
  font-size: 16px;
  line-height: 1.35;
  overflow-x: hidden;
}

a {
  color: #2980b9;
  text-decoration: none;
  transition: color 0.15s;
}

input {
  font-family: inherit;
}

.leaflet-marker-icon {
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

li {
  margin-bottom: 5px;
}

/** Ant Design Overrites **/

a:hover {
  color: unset;
}

.ant-upload.ant-upload-select {
  display: block;
}

.ant-btn:enabled.bg-success {
  background-color: #70c040;
  border-color: #70c040;
}

.ant-btn:enabled.bg-success:hover {
  background-color: #7bc84f;
  border-color: #7bc84f;
}

.ant-modal-confirm-body {
  display: flex;
}

.ant-modal-confirm-body .anticon-check-circle {
  margin-right: 0;
  margin-left: 10px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: left;
}

.ant-form-item-label {
  align-self: flex-start;
  padding: 0 !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-right: 0;
  margin-left: 4px;
}

/** React Modal Overrides **/

.ReactModal__Overlay {
  z-index: 20;
}

/** Leaflet protest card popup resets **/

.leaflet-popup-content-wrapper {
  padding: 0;
}

.leaflet-popup-content {
  margin: 0;
  font-family: Simpler, sans-serif;
  text-align: right;
}

/**======ANIMATION=====**/
/* ----------------------------------------------
 * Generated by Animista on 2020-10-17 10:23:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.slide-left-blue {
  animation: slide-left-blue 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-bottom {
  animation: slide-bottom 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-left-blue {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    background-color: white;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slide-left-blue {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    background-color: white;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }
}

/** React Hamburger Menu **/

/* Position and sizing of burger button */
.bm-burger-button {
  width: 30px;
  height: 30px;
}

#react-burger-menu-btn {
  width: inherit !important;
  height: inherit !important;
  top: inherit !important;
  left: unset !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 260px !important;
  top: 0;
  left: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #6e7dff;
  font-size: 26px;
}

@media (min-width: 360px) {
  .bm-menu-wrap {
    width: 300px !important;
  }

  .bm-menu {
    font-size: 30px;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: block;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
}

.bm-item:hover {
  color: #f6dcff;
}

/* Styling of overlay */
.bm-overlay {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3) none repeat scroll 0% 0%;
  opacity: 1;
  transition: opacity 0.3s ease 0s;
}

.bm-upper-section {
  display: flex;
}
